import {Injectable} from "@angular/core";
import {Auth, Unsubscribe, signOut, User, signInWithPopup, GoogleAuthProvider, ParsedToken} from "@angular/fire/auth";
import {BehaviorSubject, Observable, filter, from, map, mergeMap, of, tap} from "rxjs";

const roles = ['active', 'admin', 'accountant', 'developer', 'financial']
type Role = typeof roles[number];

@Injectable({providedIn: 'root'})
export class ApyreAuthService {
    private listenerUnsubscribe?: Unsubscribe;
    private _authReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public authReady$: Observable<boolean> = this._authReady$.asObservable().pipe(filter(r => !!r));
    public get currentUser() {return this.auth.currentUser};
    public get myId() {return this.auth.currentUser?.uid};
    public get loggedIn() {return !!this.auth.currentUser}
    public get displayName() {return this.currentUser?.displayName}
    private _currentUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    public currentUser$: Observable<User | null> = this._currentUser$.asObservable();
    public claims$: Observable<ParsedToken> = this.currentUser$.pipe(mergeMap(u => {
        if (!u) return of({});
        return u?.getIdTokenResult().then(result => result.claims ?? {})
    }));
    public myRoles$: Observable<string[]> = this.claims$.pipe(map(claims => {
        console.log(claims)
        return Object.entries(claims).reduce((acc: string[], [key, value]) => {
            if (roles.includes(key) && !!value) {
                acc.push(key);
            }
            return acc;
        }, []);
    }))
    public hasRole(role: Role): Observable<boolean> {
        return this.myRoles$.pipe(map(roles => roles.includes(role)));
    }

    public displayName$: Observable<string | undefined> = this.currentUser$.pipe(map((u => u?.displayName ?? undefined)));

    constructor(private auth: Auth) {
        this.listenerUnsubscribe = auth.onAuthStateChanged((state) => {
            if (!this._authReady$.value) this._authReady$.next(true);
            this._currentUser$.next(state);
        });
    }

    ngOnDestroy() {
        if (this.listenerUnsubscribe) {
            this.listenerUnsubscribe();
        }
    }
    login(): Observable<boolean> {
        if (this.auth.currentUser) return of(true);
        return from(signInWithPopup(this.auth, new GoogleAuthProvider().setCustomParameters({
            prompt: 'select_account'
        })).then((info => !!info)))
    }
    logout(): Promise<void> {
        return signOut(this.auth);
    }
}
