import {Component} from '@angular/core';
import {ApyreAuthService} from '@portal-firebase';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private authSvc: ApyreAuthService, private router: Router, private messageService: MessageService) {}
  login() {
    this.authSvc.login().subscribe({
      next: isLoggedIn => {
        if (isLoggedIn) {
          this.router.navigate(['/app'])
        }
      },
      error: e => {
        this.messageService.add({
          severity: 'error',
          summary: 'Login Failed',
          life: 5000,
          detail: 'Please login with a valid Apyre® email account.'
        })
      }

    });
  }
}
