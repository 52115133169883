import {inject} from '@angular/core';
import {CanActivateFn, CanDeactivateFn} from '@angular/router';
import {ApyreAuthService} from '@portal-firebase';
import {LoginComponent} from '../login/login.component';

export const canActivateLogin: CanActivateFn = (route, state) => {
  const auth = inject(ApyreAuthService);
  return !auth.loggedIn;
};

export const canDeactivateLogin: CanDeactivateFn<LoginComponent> = (comp, route, state) => {
  const auth = inject(ApyreAuthService);
  return auth.loggedIn;
}