export const environment = {
    env: 'development',
    firebase: {
        projectId: 'apyre-dev',
        appId: '1:700430155356:web:3274cce7f694ebcd2d3099',
        databaseURL: 'https://apyre-dev-default-rtdb.firebaseio.com',
        storageBucket: 'apyre-dev.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyCSTc-ZlFH6eb4n2ZFW0z_nKWF8VEE-ZMU',
        authDomain: 'apyre-dev.firebaseapp.com',
        messagingSenderId: '700430155356',
        measurementId: 'G-ZZM2NXLRM1',
    },
    google_key: 'AIzaSyC4ujeMriWfJMg_Ssg1YmfpgYATBOS8638',
    algolia: {
        id: 'XXMBDP6NP9',
        key: 'b282a606c5efb94d2e95164ae5a855f6'
    },
    squareAppId: 'sandbox-sq0idb-OG3aM6P0H93cQvEHzsTLEA',
    squareLocationId: 'L1V56PCR0S7K3',
    service_base: 'https://us-central1-apyre-dev.cloudfunctions.net/'
};