import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authenticationGuard} from './guards/auth.guard';
import {LoginComponent} from './login/login.component';
import {canActivateLogin, canDeactivateLogin} from './guards/login.guard';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [canActivateLogin], canDeactivate: [canDeactivateLogin]},
  {path: 'app', canLoad: [authenticationGuard], canActivate: [authenticationGuard], loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule)},
  {path: '**', redirectTo: 'app'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',

  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
