import {Component, OnDestroy} from '@angular/core';
import {ApyreAuthService} from './firebase-services/app-auth.service';
import {Observable, Subscription, combineLatest, delay, distinctUntilChanged, filter, from, fromEvent, map, merge, mergeMap, of, skip, startWith, switchMap} from 'rxjs';
import {NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  ready$: Observable<boolean> = of(true);
  unload$: Observable<boolean> = of(false);
  loadingRoute$?: Observable<boolean> = of(true);
  connected$: Observable<boolean> = of(false);
  connectedSub: Subscription;
  constructor(private auth: ApyreAuthService, private router: Router) {
    this.loadingRoute$ = this.router.events.pipe(filter(event => event instanceof RouteConfigLoadStart || event instanceof NavigationStart || event instanceof NavigationEnd), map(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof NavigationStart) {
        return true;
      } else if (event instanceof NavigationEnd) {
        return false;
      } else {
        return false;
      }
    }), distinctUntilChanged(), switchMap(loading => of(loading).pipe(delay(600))), startWith(true));
    this.ready$ = combineLatest([auth.authReady$.pipe(startWith(false)), this.loadingRoute$]).pipe(map(([authReady, load]) => authReady && !load));
    this.unload$ = this.ready$.pipe(delay(800));
    //REPLACE WINDOW WITH INJECTED OBJECT
    const online$ = fromEvent(window, 'online').pipe(map(() => true));
    const offline$ = fromEvent(window, 'offline').pipe(map(() => false));
    this.connected$ = merge(
      online$,
      offline$
    ).pipe(startWith(window.navigator.onLine));
    this.connectedSub = this.connected$.pipe(skip(1)).subscribe(connected => {
      if (connected || !google) window.location.reload()
    });

    this.loadingRoute$.subscribe(ready => console.log('LOADING', ready))
  }

  reload() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    if (this.connectedSub) this.connectedSub.unsubscribe();
  }
}
