import {Injectable} from "@angular/core";
import {Firestore, collection, collectionSnapshots, doc, docSnapshots, getDocs, limit, orderBy, query, serverTimestamp, updateDoc, DocumentData, addDoc, QueryDocumentSnapshot, QueryFieldFilterConstraint, where, getDoc} from "@angular/fire/firestore";
import {Functions, HttpsCallableResult, httpsCallable} from "@angular/fire/functions";
import {Storage, ref as storageRef, getBlob} from "@angular/fire/storage";
import {BehaviorSubject, Observable, delay, from, map, mergeMap, of, take, tap, throwError} from "rxjs";
import {CaseListing, JournalEntry, Order, Payment, PopulatedJournalEntry, ProgressActionName, ProgressGroupName, RecordChange, ServerSideCase, UserPopulatedCase} from "@structure";
import {ApyreAuthService} from "./app-auth.service";
import {LookupService} from "./lookup.service";
import {environment} from "../../environments/environment";
import {AlgoliaSearchResults, SearchListing, SearchResultsByDate} from "@structure/SearchListing.interface";
import {MessageService} from "primeng/api";
import axios from 'axios';
import {ExecutedDocument} from "@structure/ExecutedDocument.interface";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {AttachmentRecord} from "@structure/AttachmentRecord.interface";
import {TokenResult} from "@square/web-payments-sdk-types";
import {CustomerChangeRecord} from "@structure/CustomerChangeRecord.interface";
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");


@Injectable()
export class CasesDataService {
    private _myCaseListings$: Observable<CaseListing[]> = of([]);
    get myCaseListings$(): Observable<CaseListing[]> {return this._myCaseListings$};
    private _openIds: Set<string> = new Set();
    get openIds() {return Array.from(this._openIds)}
    private _myCaseListingsLoading$ = new BehaviorSubject(false);
    public myCaseListingsLoading$ = this._myCaseListingsLoading$.asObservable().pipe(mergeMap(loading => {
        if (!loading) return of(false).pipe(delay(800));
        else return of(true);
    }));
    private _lastSelected?: string | null;
    set lastSelected(caseId: string | undefined | null) {
        this._lastSelected = caseId;
        if (localStorage) {
            if (caseId) localStorage.setItem('lastCaseSelection', caseId);
            else localStorage.removeItem('lastCaseSelection');
        }
    }
    get lastSelected() {
        let lastSelected: string | undefined | null = this._lastSelected;
        if (!lastSelected && !!localStorage) lastSelected = localStorage.getItem('lastCaseSelection');
        return lastSelected;
    }


    constructor(private firestore: Firestore, private functions: Functions, private storage: Storage, private apyreAuthService: ApyreAuthService, private lookup: LookupService, private messageService: MessageService) {
        if (apyreAuthService.currentUser) {
            this._myCaseListingsLoading$.next(true);
            this._myCaseListings$ = collectionSnapshots(query(collection(firestore, "admin_portal_settings", apyreAuthService.currentUser.uid, 'case_listings'), orderBy('addedAt', 'asc'), limit(10))).pipe(
                map(docs => {
                    if (!docs || docs.length < 1) return []
                    return docs.map(d => ({id: d.id, ...d.data()}) as CaseListing);
                }),
                tap((listings: CaseListing[]) => {
                    this._myCaseListingsLoading$.next(false);
                    this._openIds = new Set(listings.map(l => l.id));
                })
            );
        }
    }



    /**
     * Generate a new, blank case (inquiry).
     * @returns {Observable<string>} Observable which emits the id of created case.
     */
    createNewCase(): Observable<string> {
        return from(httpsCallable(this.functions, 'createCase')().then(result => {
            this._openIds.add(result.data as string);
            return result.data as string
        }));
    }
    /**
     * Open case view and add case to case selection dock.
     * @param {string} caseId
     * @returns {Observable<string>} Observable which emits the id of added case.
     */
    openCase(caseId: string): Observable<string | null> {

        if (!this._openIds.has(caseId)) {
            this._myCaseListingsLoading$.next(true);
            return from(httpsCallable(this.functions, 'openCase')({caseId}).then(result => {
                this._openIds.add(caseId)
                this.lastSelected = caseId;
                return result.data as string
            }));
        } else {
            return of(caseId);
        }
    }

    /**
    * Remove case from case selection dock.
    * @param {string} caseId
    * @returns {Observable<string | null>} Observable which emits when complete.
    */
    closeCase(caseId: string): Observable<string | null> {
        if (this._openIds.has(caseId)) {
            const ids = Array.from(this._openIds);
            const current = ids.indexOf(caseId);
            const next = ids[current - 1] ?? ids[current + 1];
            this._myCaseListingsLoading$.next(true);
            return from(httpsCallable(this.functions, 'closeCase')({caseId}).then(() => {
                this._openIds.delete(caseId);
                if (this.lastSelected === caseId) {
                    this.lastSelected = undefined;
                    return next ?? null;
                } else if (this.lastSelected && this._openIds.has(this.lastSelected)) {
                    return this.lastSelected ?? null;
                } else {
                    return null;
                }
            }).finally(() => {this._myCaseListingsLoading$.next(false)}));
        } else {
            return of(null);
        }
    }

    /**
        * Remove all cases from list.
        * @returns {Observable<void>} Observable which emits when complete.
        */
    closeAll(): Observable<void> {
        this._myCaseListingsLoading$.next(true)
        return from(httpsCallable(this.functions, 'closeAllCases')().then(() => {
            this.lastSelected = undefined;
        }).finally(() => {this._myCaseListingsLoading$.next(false)}))
    }

    /**
    * Update the fields of a case.
    * @param {string} caseId
    * @param {Partial<Case>} updates Object containing only the fields to update. Set null to remove value from a field.
    * @returns {Observable<void>} Observable which emits when complete.
    */
    updateCase(caseId: string, updates: Partial<ServerSideCase> | {[key: string]: any}) {
        const ref = doc(this.firestore, 'cases/' + caseId);
        return from(updateDoc(ref, {updator: this.apyreAuthService.currentUser?.uid, writeTimestamp: serverTimestamp(), ...updates}).catch((e: Error) => {
            if (/^PERMISSION_DENIED:/.test(e.message)) {
                this.messageService.add({severity: 'error', summary: `Attempting to change protected field.`});
                throw (e);
            }
        }))
    }
    /**
    * Subscribe to a case
    * @param {string} caseId
    * @returns {Observable<Case>} Observable which emits case info and re-emits on any change.
    */
    getCaseDataObservable(caseId: string): Observable<UserPopulatedCase> {
        return docSnapshots(doc(this.firestore, 'cases', caseId)).pipe(map(doc => {
            const data = doc.data();
            if (!data) throw 'No case found.';
            return ({id: doc.id, ...data, created_by: data['created_by'] ? this.lookup.user(data['created_by']) : data['created_by']} as UserPopulatedCase);
        }));

    }
    /**
    * Subscribe to case change log.
    * @param {string} caseId
    * @returns {Observable<any>} Observable which emits changes info and re-emits on any additions.
    */
    getCaseChangesObservable(caseId: string): Observable<RecordChange[]> {
        return collectionSnapshots(query(collection(this.firestore, 'cases', caseId, 'changes'), orderBy('date', 'desc'))).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => d.data()).map((c: any) => ({user: this.lookup.user(c.user), ...c}));
        }));
    }
    /**
    * Subscribe to case orders.
    * @param {string} caseId
    * @returns {Observable<any>} Observable which emits changes info and re-emits on any additions.
    */
    getCaseOrdersObservable(caseId: string): Observable<Order[]> {
        return collectionSnapshots(query(collection(this.firestore, 'cases', caseId, 'orders'), orderBy('created_at'))).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => ({...d.data(), id: d.id})) as Order[];
        }));
    }
    /**
    * Subscribe to case payments.
    * @param {string} caseId
    * @returns {Observable<any>} Observable which emits changes info and re-emits on any additions.
    */
    getCasePaymentsObservable(caseId: string): Observable<Payment[]> {
        return collectionSnapshots(query(collection(this.firestore, 'cases', caseId, 'payments'), orderBy('created_at'))).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => ({...d.data(), id: d.id})) as Payment[];
        }));
    }
    /**
    * Searchable list of cases.
    * @param {string} page For Server Side Pagination
    * @param {string} searchTerm Term to search inquiryNumber, caseNumber, Decedent Name, Caller Name, email, phone.
    * @returns {Observable<any>} List of up to 20 Cases in DESC order.
    */
    searchCases(search: string, page: number = 0, limit: number = 50, active: boolean, state: string): Observable<SearchResultsByDate> {
        return from(httpsCallable(this.functions, 'searchCases')({search, page, limit, active, state}).then(result => {
            const results = result.data as AlgoliaSearchResults;
            return {
                ...results, listings: Array.from(results.listings.reduce((acc: Map<string, SearchListing[]>, listing) => {
                    const d = dayjs.unix(listing.dateCreated / 1000).startOf('day').valueOf().toString();
                    if (!acc.has(d)) {
                        acc.set(d, [listing]);
                    } else {
                        acc.get(d)?.push(listing);
                    }
                    return acc;
                }, new Map()).entries()).map(([key, value]) => ({timestamp: Number(key), items: value}))
                //.sort((a, b) => (a.timestamp < b.timestamp) ? -1 : 1)
            };
        }))
    }

    /**
   * Use PlaceID to set service Location..
   * @param {string} caseId
   * @param {string} placeId
   * @returns {Observable<string>} Observable containing ID of closest service location..
   */
    processPlaceId(caseId: string | undefined, placeId: string | undefined) {
        if (!caseId || !placeId) return of(null);
        return from(httpsCallable(this.functions, 'processPlaceId')({caseId, placeId}).then(result => result.data));
    }

    /**
   * Add Journal Entry to Case Journal
   * @param {string} caseId
   * @param {string} message
   * @returns {Observable<void>}
   */
    addJournalEntry(caseId: string, message: string): Observable<string> {
        if (!caseId || !message || !this.apyreAuthService.myId) throw throwError(() => new Error('Improper Input'));
        const col = collection(this.firestore, 'cases', caseId, 'journal');
        return from(addDoc(col, {caseId, message, user: this.apyreAuthService.myId, date: serverTimestamp()})).pipe(map(doc => doc.id));

    }

    /**
   * Get Journal Entries
   * @param {string} caseId
   * @returns {Observable<void>}
   */
    getJournalEntries(caseId: string): Observable<PopulatedJournalEntry[]> {
        if (!caseId) throw throwError(() => new Error('Improper Input'))
        const q = query(collection(this.firestore, 'cases', caseId, 'journal'), orderBy('date', 'asc'))
        return collectionSnapshots(q).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => d.data() as JournalEntry).map((entry: JournalEntry) => ({...entry, isOwn: this.lookup.user(entry.user)?.id === this.apyreAuthService.myId, user: this.lookup.user(entry.user)} as PopulatedJournalEntry));
        }));
    }

    getCustomerSubmissionObservable(caseId: string): Observable<CustomerChangeRecord | null> {
        if (!caseId) throw throwError(() => new Error('Improper Input'));
        const q = doc(this.firestore, 'customer_submissions', caseId);
        return from(getDoc(q).then(doc => (doc.data() as CustomerChangeRecord) ?? null));
    }
    getCustomerSubmissionPromise(caseId: string): Promise<CustomerChangeRecord | null> {
        if (!caseId) throw throwError(() => new Error('Improper Input'));
        const q = doc(this.firestore, 'customer_submissions', caseId);
        return getDoc(q).then(doc => (doc.data() as CustomerChangeRecord) ?? null);
    }


    sendPriceQuote(caseId: string, email: string): Observable<boolean> {
        return from(httpsCallable(this.functions, 'sendQuote')({caseId, email}).then(result => {
            return !!result.data;
        }));
    }


    sendForProvisioning(caseId: string): Observable<boolean> {
        return from(httpsCallable(this.functions, 'provision')({caseId}).then((result: HttpsCallableResult) => !!result.data));
    }

    sendForDispatch(caseId: string, funeralDirector: {name: string, license: string, email: string}, customForms: string[], shipments: any, payment: {type: string, token?: TokenResult}): Observable<boolean> {
        return from(httpsCallable(this.functions, 'dispatch')({caseId, shipments, customForms, payment, funeralDirector}).then(result => {
            return !!result.data;
        }));
    }

    downloadDocuments(caseId: string, docs: {standard?: string[] | null, custom?: string[] | null}): Observable<ArrayBuffer | null> {
        const tokenPromise = this.apyreAuthService.currentUser?.getIdToken();
        if (tokenPromise) return from(tokenPromise?.then((token) => {
            return axios.post(environment.service_base + 'downloadDocuments', {caseId, docs}, {responseType: 'arraybuffer', headers: {Authorization: 'Bearer ' + token}}).then(result => {
                return result.data
            })
        }))
        return of(null);
    }

    /**
     * Commit chosen customer data to case.
     * @returns {Observable<void>} Observable which emits the id of created case.
     */
    commitCustomerChanges(caseId: string, allChanges?: boolean, changes?: {[fieldName: string]: any}): Promise<void> {
        return httpsCallable(this.functions, 'commitCustomerChanges')({customerChanges: changes ?? {}, caseId, allChanges: !!allChanges}).then(() => {});
    }

    test(): Observable<any> {
        return from(httpsCallable(this.functions, 'test')().then(result => {
            return result.data;
        }));
    }

    migrate(inquiryNumber: string): Observable<any> {
        return from(httpsCallable(this.functions, 'migrate')({inquiryNumber}).then(result => {
            return result.data;
        }));
    }

    updateDocusignDocuments(caseId: string) {
        return from(httpsCallable(this.functions, 'updateDocusignDocuments')({caseId}).then(result => {
            return result.data;
        }));
    }



    getExecutedDocumentRecords(caseId: string): Observable<ExecutedDocument[]> {
        if (!caseId) throw throwError(() => new Error('Improper Input'))
        const q = query(collection(this.firestore, 'cases', caseId, 'executeddocuments'))
        return collectionSnapshots(q).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => d.data() as ExecutedDocument);
        }));
    }
    getAttachmentRecords(caseId: string): Observable<AttachmentRecord[]> {
        if (!caseId) throw throwError(() => new Error('Improper Input'))
        const ref = collection(this.firestore, 'cases', caseId, 'attachments');
        const q = query(ref, where('active', '==', true));
        return collectionSnapshots(q).pipe(map(docs => {
            if (!docs || docs.length < 1) return [];
            return docs.map(d => ({...d.data(), id: d.id}) as AttachmentRecord);
        }));
    }

    getExecutedDocs(fileName: string): Observable<ArrayBuffer> {
        const ref = storageRef(this.storage, `gs://apyre-${environment.env === 'production' ? 'prod' : 'dev'}-executeddocuments/` + fileName);
        return from(getBlob(ref).then(result => result.arrayBuffer()));
    }
    getAttachment(fileName: string): Observable<ArrayBuffer> {
        const ref = storageRef(this.storage, `gs://apyre-${environment.env === 'production' ? 'prod' : 'dev'}-attachments/` + fileName);
        return from(getBlob(ref).then(result => result.arrayBuffer()));
    }

    deleteAttachment(caseId: string, attachmentId: string) {
        return from(httpsCallable(this.functions, 'deleteAttachment')({caseId, attachmentId}).then(result => {
            return result.data;
        }));
    }

    getDocCertificate(fileName: string): Observable<ArrayBuffer> {
        const ref = storageRef(this.storage, `gs://apyre-${environment.env === 'production' ? 'prod' : 'dev'}-documentcertificates/` + fileName);
        return from(getBlob(ref).then(result => result.arrayBuffer()));
    }

    resendLegacyObitLink(name: string, email: string, obitLink: string) {
        return from(httpsCallable(this.functions, 'resendLegacyObitLink')({name, email, obitLink}).then(result => {
            return result.data;
        }));
    }

    grantCustomerAccess(email: string, caseId: string) {
        return from(httpsCallable(this.functions, 'grantCustomerAccess')({email, caseId}).then(result => {
            return result.data;
        }));
    }

    revokeCustomerAccess(caseId: string) {
        return from(httpsCallable(this.functions, 'grantCustomerAccess')({caseId}).then(result => {
            return result.data;
        }));
    }

    stampPredefined(caseId: string, action: ProgressActionName) {
        return from(httpsCallable(this.functions, 'stampPredefined')({caseId, action}).then(result => {
            return result.data;
        }));
    }
    stampDynamic(caseId: string, text: string, group: ProgressGroupName) {
        return from(httpsCallable(this.functions, 'stampDynamic')({caseId, text, group}).then(result => {
            return result.data;
        }));
    }




}