import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {provideAuth, getAuth, Auth, connectAuthEmulator} from '@angular/fire/auth';
import {provideDatabase, getDatabase, connectDatabaseEmulator, Database} from '@angular/fire/database';
import {provideFirestore, getFirestore, Firestore, connectFirestoreEmulator} from '@angular/fire/firestore';
import {provideFunctions, getFunctions, Functions} from '@angular/fire/functions';
import {provideMessaging, getMessaging} from '@angular/fire/messaging';
import {providePerformance, getPerformance} from '@angular/fire/performance';
import {provideRemoteConfig, getRemoteConfig} from '@angular/fire/remote-config';
import {provideStorage, getStorage} from '@angular/fire/storage';
import {LoginComponent} from './login/login.component';
import {connectFunctionsEmulator} from '@firebase/functions';
import {ApyreAuthService} from 'projects/portal/src/app/firebase-services';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AnimateModule} from 'primeng/animate';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PwaService} from './shared/services/pwa/pwa.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    AnimateModule,
    ToastModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    ApyreAuthService,
    MessageService,
    PwaService
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: ApyreRouteReuseStrategy
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(functions: Functions, firestore: Firestore, auth: Auth, database: Database, pwsService: PwaService) {
    if (environment.env === 'local') {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
      connectDatabaseEmulator(database, "127.0.0.1", 9000);
      connectAuthEmulator(auth, "http://localhost:9099", {disableWarnings: true});
    }
    if (environment.env === 'production') {
      pwsService.checkForUpdates();
    }
  }
}
