<router-outlet></router-outlet>
<p-toast></p-toast>
<div *ngIf="!(unload$ | async)" [ngClass]="{fadeout:ready$ | async}" class="loading">
    <img class="logo flitter" [ngClass]="{flyout:ready$ | async}" src="assets/images/apyre_logo.svg" />
</div>
<div *ngIf="!(connected$ | async)" class="no-connection flex-column">
    <div class="flex flex-column align-items-center justify-content-center">
        <img style="width: 50%; max-width: 200px" src="assets/images/apyre_logo.svg" />
        <h3 class="text-center p-2">Oops... it looks like you've lost interent connection.</h3>
    </div>
</div>