import {inject} from "@angular/core";
import {ApyreAuthService} from "projects/portal/src/app/firebase-services";
import {CanMatchFn, Router} from "@angular/router";
import {map, take, tap} from "rxjs";

export const authenticationGuard: CanMatchFn = () => {
    const authSvc: ApyreAuthService = inject(ApyreAuthService);
    const router: Router = inject(Router);
    return authSvc.authReady$.pipe(take(1), map(() => authSvc.currentUser), map(u => !!u), tap((u) => {
        if (!u) router.navigate(['/login'])
    }))
};