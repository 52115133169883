import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';

if (environment.env === 'production') {
  console.log(environment.env);
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
