import {Injectable} from "@angular/core";
import {Firestore, doc, docSnapshots, setDoc, updateDoc} from "@angular/fire/firestore";
import {Observable, from, map, of} from "rxjs";
import {ApyreAuthService} from "./app-auth.service";
import {PortalSettings} from "@structure/PortalSettings.interface";

@Injectable({providedIn: 'root'})
export class UserDataService {
    private _mySettings$: Observable<PortalSettings | undefined> = of(undefined);
    get mySettings$(): Observable<PortalSettings | undefined> {return this._mySettings$}

    constructor(private firestore: Firestore, private auth: ApyreAuthService) {
        if (auth.currentUser?.uid) {
            this._mySettings$ = docSnapshots(doc(firestore, "admin_portal_settings", auth.currentUser?.uid)).pipe(map(doc => {
                return doc.data() as PortalSettings;
            }));
        }
    }

    updateSettings(settings: {notifications?: [], notificationsPhone?: string}): Observable<boolean> {
        if (this.auth.currentUser?.uid) {
            const ref = doc(this.firestore, "admin_portal_settings", this.auth.currentUser?.uid);
            return from(setDoc(ref, settings, {merge: true})).pipe(map(() => true));
        }
        return of(false);
    }
}