import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
@Injectable()
export class PwaService {
    constructor(private swUpdate: SwUpdate) {}
    checkForUpdates() {
        this.swUpdate.checkForUpdate().then((available) => {
            if (available) window.location.reload();
        });
    }
}