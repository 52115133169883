import {Injectable, OnDestroy, Query} from "@angular/core";
import {Firestore, collection, collectionSnapshots, orderBy, query} from "@angular/fire/firestore";
import {User} from "@structure/User.interface";
import {BehaviorSubject, Observable, Subscription, combineLatest, distinctUntilChanged, from, map, of, startWith, switchMap} from "rxjs";
import {ApyreAuthService} from "./app-auth.service";
import {ApyreLocation} from "@structure/ApyreLocation.interface";
import {Functions, httpsCallable} from "@angular/fire/functions";
import {CustomFormRecord} from "@structure/CustomFormRecord";
import {SelectItem} from "primeng/api";
import {BaseStats} from "@structure/Stats.interface";
import {Database, list, listVal, object, objectVal, ref} from "@angular/fire/database";

@Injectable()
export class LookupService implements OnDestroy {
    private _users$: BehaviorSubject<{[key: string]: User}> = new BehaviorSubject({});
    public users$: Observable<{[key: string]: User}> = this._users$.asObservable();
    private usersSub: Subscription;
    private _locations$: BehaviorSubject<{[key: string]: ApyreLocation}> = new BehaviorSubject({});
    public locations$: Observable<{[key: string]: ApyreLocation}> = this._locations$.asObservable();
    private locationsSub: Subscription;
    private _customForms$: BehaviorSubject<{[key: string]: CustomFormRecord}> = new BehaviorSubject({});
    public customForms$: Observable<{[key: string]: CustomFormRecord}> = this._customForms$.asObservable();
    private customFormsSub: Subscription;
    private _stats$: BehaviorSubject<BaseStats> = new BehaviorSubject({} as any);
    public stats$: Observable<BaseStats> = this._stats$.asObservable();
    private statsSub: Subscription;
    constructor(firestore: Firestore, private auth: ApyreAuthService, database: Database, private functions: Functions) {
        this.usersSub = this.auth.currentUser$.pipe(switchMap((user) => {
            return !!user ? collectionSnapshots(collection(firestore, 'user_metadata')) : []
        })).subscribe(docs => {
            this._users$.next(docs.map(doc => ({id: doc.id, ...doc.data()})).reduce((acc: {[id: string]: any}, item) => {
                acc[item.id] = item;
                return acc;
            }, {}));

        });
        this.locationsSub = this.auth.currentUser$.pipe(switchMap((user) => {
            return !!user ? collectionSnapshots(collection(firestore, 'locations')) : []
        })).subscribe(docs => {
            this._locations$.next(docs.map(doc => ({id: doc.id, ...doc.data()})).reduce((acc: {[id: string]: any}, item) => {
                acc[item.id] = item;
                return acc;
            }, {}))
        });
        this.customFormsSub = this.auth.currentUser$.pipe(switchMap((user) => {
            return !!user ? collectionSnapshots(query(collection(firestore, 'customforms'), orderBy('name'))) : []
        })).subscribe(docs => {
            this._customForms$.next(docs.map(doc => ({id: doc.id, ...doc.data()})).reduce((acc: {[id: string]: any}, item) => {
                acc[item.id] = item;
                return acc;
            }, {}))
        })

        this.statsSub = this.auth.hasRole('financial').pipe(distinctUntilChanged(), switchMap((hasFinancial) => {
            const q = [
                object(ref(database, 'stats/overview')).pipe(startWith({snapshot: {val: () => ({})}}), map((r: any) => r.snapshot.val())),
                object(ref(database, 'stats/quantities')).pipe(startWith({snapshot: {val: () => ({})}}), map((r: any) => r.snapshot.val())),
                object(ref(database, 'stats/merchandiseQuantities')).pipe(startWith({snapshot: {val: () => ({})}}), map((r: any) => r.snapshot.val())),
                hasFinancial ? object(ref(database, 'stats/financial')).pipe(startWith({snapshot: {val: () => ({})}}), map((r: any) => r.snapshot.val())) : of({}),
                hasFinancial ? object(ref(database, 'stats/merchandiseFinancial')).pipe(startWith({snapshot: {val: () => ({})}}), map((r: any) => r.snapshot.val())) : of({})
            ];
            return combineLatest(q);
        })).subscribe(result => {
            const struct = {
                overview: result[0] as BaseStats['overview'] ?? {},
                quantities: result[1] as BaseStats['quantities'] ?? {},
                merchandiseQuantities: result[2] as BaseStats['merchandiseQuantities'] ?? null,
                financial: result[3] as BaseStats['financial'] ?? null,
                merchandiseFinancial: result[4] as BaseStats['merchandiseFinancial'] ?? null
            };
            this._stats$.next(struct);
        })

    }
    ngOnDestroy(): void {
        if (this.usersSub) {
            this.usersSub.unsubscribe();
        }
        if (this.locationsSub) {
            this.locationsSub.unsubscribe();
        }
        if (this.customFormsSub) {
            this.customFormsSub.unsubscribe();
        }
        if (this.statsSub) {
            this.statsSub.unsubscribe();
        }
    }
    user(userId: string): User | undefined {
        return this._users$.value[userId];
    }

    me(): User | undefined {
        return this.auth.myId ? this._users$.value[this.auth.myId] : undefined;
    }

    userChanges(userId: string): Observable<User | undefined> {
        return this.users$.pipe(map(u => u[userId]));
    }

    meChanges(): Observable<User | undefined> {
        return !!this.auth.myId ? this.users$.pipe(map(u => u[this.auth.myId!])) : of(undefined);
    }

    getPhotoUrl(userId?: string): string | undefined {
        return userId ? this._users$.value[userId]?.photoUrl : this.auth.myId ? this._users$.value[this.auth.myId]?.photoUrl : undefined;
    }

    getPhotoUrlChanges(userId?: string): Observable<string | undefined> {
        return (userId ? this.userChanges(userId).pipe(map(u => u?.photoUrl)) : this.auth.myId ? this.meChanges().pipe(map(u => u?.photoUrl)) : of(undefined)).pipe(map(url => !!url ? url : '/assets/images/apyre-avatar-default.png'))
    }

    apyreLocation(locationId: string): ApyreLocation | undefined {
        return this._locations$.value[locationId];
    }

    apyreLocationsByState(state: string): Observable<ApyreLocation[]> {
        return this.locations$.pipe(map(locs => !locs ? [] : Object.keys(locs).map(key => this._locations$.value[key]).filter(item => item.state === state)));
    }

    funeralDirectorsListByState(state: string): Observable<{label: string, value: string}[]> {
        return this.users$.pipe(map(u => Object.entries(u).reduce((acc: {label: string, value: string}[], [key, u]) => {
            if (u.licenses && u.licenses[state]) {
                acc.push({
                    label: u.displayName,
                    value: u.id
                });
            }
            return acc;
        }, [])));
    }

    funeralDirectorInfoByIdAndState(userId: string, state?: string): {name: string, license: string, email: string} | null {
        if (!userId || !state) return null;
        const user = this.user(userId);
        return !!user && user.licenses && user.licenses[state] ? {name: user.displayName, license: '#' + user.licenses[state], email: user.email} : null;
    }

    getServiceStates(): Observable<string[]> {
        return this.locations$.pipe(map(locations => {
            return Array.from(Object.entries(locations).reduce((acc: Set<string>, [key, item]: [key: string, item: ApyreLocation]) => {
                acc.add(item.state);
                return acc;
            }, new Set()));
        }))
    }

    getCustomFormListings() {
        return Object.values(this._customForms$.value).reduce((acc: SelectItem<string>[], form: any) => {
            acc.push({label: form.name, value: form.filename} as SelectItem<string>);
            return acc;
        }, [] as SelectItem<string>[]);
    }

}